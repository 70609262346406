@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$small-screen: 768px;
$card-border-radius: 10px;

.card {
  display: inline-flex;
  flex-direction: column;
  border-radius: $card-border-radius;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  height: $list-card-size;
  margin-bottom: 15px;
  width: 320px;
  margin-right: 20px;
  text-decoration: unset;

  &.blackout {
    background-color: #1f2531;
  }

  &:last-child {
    margin-right: 0;
  }

  @include screen(max-sm) {
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    position: relative;
  }

  .image {
    overflow: hidden;
    margin: 0;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom-left-radius: 0px;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;

    img {
      object-fit: cover;
    }
  }

  .image::after {
    -moz-box-shadow: inset 0 39px 35px 0px #0000147d;
    -webkit-box-shadow: inset 0 39px 35px 0px #0000147d;
    box-shadow: inset 0 39px 35px 0px #0000147d;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  > .information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    width: auto;
    height: 100%;

    > .tags {
      margin-bottom: 2px;
      display: flex;
      align-items: center;
    }

    > .values {
      display: flex;
      border-left: 1px solid $gray0;
      width: 100%;
      align-self: center;
      flex-direction: column;
      justify-content: flex-start;
      border: none;
      margin: 10px 0 0;
      margin-bottom: 12px;

      > span {
        text-align: left;

        > .saleValue {
          color: $secondary-color;
        }
      }

      [class*='row'] {
        p {
          color: $secondary-color;
        }
      }
      .cardValues {
        display: flex;
        flex-direction: column;
      }
    }

    > header {
      position: relative;
      text-align: start;
      .propertyText {
        white-space: nowrap;
        color: $secondary-color;

        &.blackout {
          color: $white-color;
        }

        &.blackoutAdress {
          color: $line-color;
        }
      }
      [class*='wrapper'] {
        position: initial;
      }
      [class*='tooltip'] {
        width: 250px;
        color: $secondary-color;
      }
      span {
        all: unset;

        color: $secondary-color;
        font-size: $font-size-14;

        &.cityInBlackout {
          color: $white-color;
        }
      }
    }

    > .features {
      @include flexBox(row, flex-start);
      height: 32px;
      margin-top: 5px;

      > figure {
        margin-right: 10px;

        use {
          fill: $secondary-color;
        }

        p {
          color: $secondary-color;
        }
      }
    }
  }
}

.ellipsis {
  font-size: $font-size-14;
  color: $black-color;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: initial;

  @include screen(max-md) {
    margin-top: 0.5em;
  }
}

.link {
  text-decoration: none;
  color: $secondary-color;
  font-weight: bold;
  font-size: $font-size-18;
  text-align: left;
}

.bankLogo {
  position: absolute;
  top: 4%;
  right: 4%;
  max-width: 160px;
  max-height: 60px;
  z-index: 1;

  &.leftPositioned {
    right: 0;
    left: 4%;
  }
}

.bancoInter,
.tribanco,
.portoSeguro,
.rooftop,
.banese,
.randon,
.brk,
.poupex,
.bancoGPA,
.bancoSemear,
.bancoTopazio,
.bancoBRB {
  max-width: 100px;
  max-height: 32px;
}

.bancoDaycoval {
  max-width: 120px;
  max-height: 48px;
  top: 10%;
  right: 2%;
}

.sold {
  & .soldTag {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    display: flex;
    top: 13%;
    right: -28%;
    padding: 5px 0;
    text-align: center;
    transform: rotate(40deg);
    height: 25px;

    span {
      background-color: $tertiary-color;
      color: $white-color;
      width: 93%;
      font-size: $font-size-14;
      font-weight: bold;
      letter-spacing: 0.9px;
    }

    @include screen(xs) {
      top: 10%;
      width: 80%;
    }

    @include screen(max-sm) {
      width: 100%;
    }

    @include screen(min-md) {
      width: 80%;
    }

    @include screen(max-md) {
      width: 94%;
    }

    @include screen(min-lg) {
      width: 100%;
    }
  }
}

.desagio {
  @include flexBox(row, space-around);
  border-radius: 5px;
  width: 50px;
  padding: 0 5px;
  position: absolute;
  left: 4%;
  bottom: 4%;
  background-color: $tertiary-color;
  color: white;
  font-size: $font-size-16;
  font-weight: bold;
}

.newTag {
  position: absolute;
  top: 5%;
  left: 4%;
  z-index: 1;

  > span {
    padding: 3px 9px;
    margin: 0;
  }
}

.novidade,
.emBreve {
  background-color: #fffffe;
  border-radius: 13px;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
  position: absolute;
  top: 18px;
  left: 18px;
  z-index: 1;
  @include flexBox();

  span {
    margin-left: 4px;
  }
  img {
    width: 14px;
    height: 14px;
  }
}
.emBreve {
  z-index: inherit;
}

.saleValueStrike {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: line-through;
  color: #666666 !important;
  margin-bottom: 8px;
}
