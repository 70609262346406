@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.allFeaturesCondominium {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  border-radius: 5px;
  background-color: $background-color;
  margin-bottom: 28px;
  padding: 26px;
  gap: 8px;

  .featureItem {
    display: flex;
    align-items: center;
    gap: 8px;

    span,
    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }

  [class*='MoreFeatures_text'] {
    p {
      color: $secondary-color;
    }
  }
}
