@import '../../assets/scss/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  > .close {
    position: absolute;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    top: 8px;
    right: 10%;
    z-index: 1;
    color: $primary-color;

    &:disabled {
      display: none;
    }
  }

  .input {
    height: 40px;

    svg {
      cursor: pointer;
    }
  }
}

svg {
  cursor: pointer;
}
