@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.AsteriskExplain {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 90%;
  padding: 1em;
  margin: 1em 0;
  text-align: right;
  background-color: $asterisk-background-color;

  @include screen(max-md) {
    background-color: $lavender0;
  }
}

.Fenab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  color: black;
  gap: 15px;

  > span {
    font-size: 14px;
    text-align: center;
  }

  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 1rem;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  .Modal {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
