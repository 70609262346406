@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$alert-card-padding: 22px;

.alertCard {
  @include flexBox(column, center, center);
  box-sizing: border-box;
  width: 320px;
  height: $list-card-size;
  border-radius: 12px;
  background-color: $blue1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 26px 16px 34px 16px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .figure {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    border-radius: 50%;

    .img {
      width: 96px;
      height: 96px;
    }
  }

  .body {
    align-items: center;
    width: 100%;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      text-align: center;
    }
    > h2 {
      font-size: 18px;
      margin-top: 12px;
    }
  }

  .text {
    font-size: 16px;
    margin-top: 8px;
  }

  .textBold {
    font-size: 16px;
    font-weight: 700;
    margin-top: 4px;
    width: 80%;
  }

  .emailInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
    width: 100%;

    .emailInput {
      width: 98%;
      height: 40px;

      label {
        margin: 0;
      }

      input {
        border-radius: 4px;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .buttonSubmit {
    width: 160px;
    height: 40px;
    border-radius: 8px;
  }

  @include screen(xxs) {
    width: 90vw !important;
  }

  @include screen(xs) {
    width: 85vw;
  }
}
