@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.didYouKnowContainer {
  box-sizing: border-box;
  background-color: $blue1;
  position: relative;
  padding: 16px;
  width: 100%;
  z-index: 1;
  &.closed {
    display: none;
  }
  > .headerNotification {
    @include flexBox(row, space-between, center);
    margin-bottom: 8px;
    > .title {
      font-weight: 600;
      font-size: $font-size-18;
      line-height: 20px;
      color: $white-color;
      align-self: center;
    }
    > .iconContainer {
      @include flexBox();
      width: 20px;
      height: 20px;
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        :hover {
          filter: opacity(0.8);
          transition: all 400ms ease-in-out;
        }
        img {
          align-self: flex-start;
        }
      }
      @media (min-width: 800px) {
        display: none;
      }
    }
  }
  > .info {
    color: $white-color;
    font-size: $font-size-14;
    line-height: 16px;
    a {
      color: $white-color;
      font-size: $font-size-14;
    }
  }
  > .iconContainerBig {
    display: none;

    @media (min-width: 800px) {
      display: inline-block;
      align-self: flex-start;
      position: absolute;
      right: 4px;
      top: 4px;
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        :hover {
          filter: opacity(0.8);
          transition: all 400ms ease-in-out;
        }
        img {
          align-self: flex-start;
        }
      }
    }
  }
}

.ligthBackground {
  background-color: $primary-color !important;
}

@media (min-width: 800px) {
  .didYouKnowContainer {
    @include flexBox(row, flex-start, flex-start);
    padding: 16px 32px 8px;
    max-height: 140px;
  }
  .divider {
    border-right: 2px solid #3d5992;
    height: 32px;
    display: inline-block;
    margin: 0 16px;
  }
  .info {
    max-width: calc(90% - 5vw);
  }
}

.dividerWhite {
  border-color: $white-color !important;
}
