@import '../../assets/scss/variables.scss';

.toast {
  padding: 1em !important;
  border-radius: 4px !important;
  background: white !important;
  box-shadow: 2px 2px 5px black;
  color: $blue1 !important;
}

.success {
  background: $primary-color !important;
}

.error {
  background: $tertiary-color !important;
}
