.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 0 20px 0;

  @media (max-width: 768px) {
    padding:24px 10px 20px 10px;
  }

  span {
    font-weight: 400;
    color: #262626;
    font-size: 1rem;
  }
}