@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.filtersContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 5px;
  margin-bottom: 20px;
}

.filterSection {
  padding: 5px;
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 20px;

  > section {
    margin: 1em 0px;

    > h3 {
      margin-bottom: 16px;
    }
  }

  @include screen(xs) {
    width: unset;
    max-width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;

  > p {
    margin-right: 10px;
  }

  button {
    cursor: pointer;
    padding: 0;
    border: 0;
    color: $black-color;
    text-decoration: underline;
    background: none;
    font-size: $font-size-14;
    font-weight: 600;
    line-height: 21px;
    position: absolute;
    right: 0;

    &:focus,
    &:hover {
      outline: none;
      text-decoration: none;
    }
  }

  @include screen(min-sm) {
    button {
      display: none;
    }

    > p {
      margin-right: unset;
    }
  }
}

.confirmButtonContainer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: $white-color;
  padding: 15px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;

  @include screen(xs) {
    padding-bottom: calc(6vh + 8%);
  }
}

.confirmButtonContainerIos {
  @include screen(xs) {
    padding-bottom: calc(4vh + 12%) !important;
  }
}

.confirmButton {
  align-self: center;
  width: 30%;
  height: 52px;
  background-color: $tertiary-color;
  border-radius: 7px;

  @include iOS {
    margin-bottom: 50px;
  }

  @include screen(xs) {
    width: 100%;
  }
}

.clearButton {
  align-self: center;
  width: 30%;
  height: 52px;
  border-radius: 7px;

  @include iOS {
    margin-bottom: 50px;
  }

  @include screen(xs) {
    display: none;
  }
}

.modal {
  max-width: 545px;
  padding: 24px;
  padding-bottom: 0;
  border: 1px solid transparent;
  border-radius: 16px;
  z-index: 10;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray3;
    border-radius: 16px;
  }

  @include screen(xs) {
    max-height: 100vh;
    padding-top: 15vh;

    span[id*='closeModal'] {
      padding-top: 10vh;
    }
  }

  > span {
    top: 37px !important;

    @include screen(xs) {
      top: 37px !important;
    }
  }
}
