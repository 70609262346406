@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.bannerIconWrapper {
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding-left: 8px;

  .button {
    box-sizing: border-box;
    all: unset;
    background: transparent;
    border: transparent;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    cursor: pointer;
    border-radius: 16px;
    height: 32px;

    > p {
      margin-left: 8px;
      white-space: nowrap;
    }
  }

  .buttonSelected {
    box-sizing: border-box;
    padding: 10px 20px;
    background-color: $tertiary-color;
  }

  .selectedFastProposal {
    background-color: #199b45;
  }

  .selectedResaleFast {
    background-color: $tertiary-color;
  }

  @include screen(xs) {
    justify-content: flex-start;
  }
}
