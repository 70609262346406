@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.howItWorks {
  margin-top: 200px;
  .mainTitle {
    text-align: center;
    line-height: 33px;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 24px;

    @include screen(max-md) {
      padding: 0 16px;
      margin-bottom: 24px;
    }
    @include screen(xxl) {
      margin-top: 150px;
    }
    @media (min-width: 2700px) {
      margin-top: 250px;
    }
  }
  .container {
    box-sizing: border-box;
    background-image: url('../../assets/images/home/svgs/backgroundWork.svg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    height: auto;
    max-width: 1200px;
    margin: 0 auto;
    @include flexBox(row, space-between);
    padding: 24px 64px;

    @include screen(max-md) {
      width: 100%;
      background-image: none;
      overflow-x: scroll;
      padding: 16px;
    }

    > .card {
      width: 300px;
      margin: 0 auto;
      min-height: 440px;
      height: 100%;
      @include flexBox(column);

      @include screen(max-md) {
        width: 100%;
        background-color: #f8f8f8;
        margin-right: 16px;
        min-width: 300px;
        max-height: 440px;
        border-radius: 16px;
        padding: 8px;

        .card:last-child {
          margin-right: 0;
        }
      }

      > figure {
        margin-bottom: 36px;
        @include screen(max-sm) {
          margin-bottom: 0;
        }
        > img {
          width: 250px;
          height: 250px;
        }
      }
      > h3 {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 24px;
        @include screen(max-sm) {
          margin-bottom: 12px;
        }
      }
      > p {
        text-align: center;
        line-height: 24px;
        max-width: 260px;
        min-height: 100px;
        @include screen(max-sm) {
        }
      }
    }
  }
}
