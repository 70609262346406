@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins.scss';

:root {
  --myColor: black;
}

* {
  font-family: $font-family;
}

.wf-loading:not(.wf-inactive),
.wf-loading:not(.wf-inactive) * {
  color: transparent !important;
}

@font-face {
  font-family: 'UniviaPro';
  src: url('../fonts/UniviaPro-Regular.woff2') format('woff2'),
    url('../fonts/UniviaPro-Regular.woff') format('woff'),
    url('../fonts/UniviaPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'UniviaPro';
  src: url('../fonts/UniviaPro-BookItalic.woff2') format('woff2'),
    url('../fonts/UniviaPro-BookItalic.woff') format('woff'),
    url('../fonts/UniviaPro-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
