@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.container {
  .share {
    display: flex;
    flex-direction: column-reverse;

    @include screen(max-md) {
      flex-direction: column-reverse;
      align-items: center;
      .alignLeft {
        margin-right: auto;
      }
    }

    > .propertyTitle {
      width: 100%;
      padding-top: 24px;
    }
  }
  .button {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-bottom: 24px;

    > img {
      width: 100%;
      object-fit: cover;
      cursor: default;
    }
  }

  .backgroundBb {
    width: 100%;
    max-width: 780px;
    height: 84px;

    @include screen(xs) {
      height: 50px;
      object-fit: contain;
    }
  }

  .tagsContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
    position: relative;
    flex-wrap: wrap;
  }

  .propertyIdText {
    margin-top: 12px;
  }

  .darkColor {
    color: $secondary-color;
  }

  .address {
    display: flex;
    flex-direction: row;
    align-items: center;
    .googleMapsLogo {
      max-width: 30px;
    }
  }

  .carouselContainer {
    width: 100%;
    height: 84px;

    ul {
      max-height: 84px !important;
    }

    @media (max-width: 768px) {
      .arrowCarousel {
        display: none !important;
      }
    }

    .dots {
      @media (max-width: 650px) {
        margin-bottom: 12px;
        li > button {
          width: 8px;
          height: 8px;
        }
      }

      li > button {
        border-color: transparent;
      }
      li[class='react-multi-carousel-dot react-multi-carousel-dot--active']
        > button {
        background: $primary-color !important;
      }
    }

    button {
      span {
        width: 40px !important;
        height: 40px !important;
        margin: 0 10px;
      }
    }
  }
}
