@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .txt {
    color: #262626;
  }

  .featuredFeatures {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @include screen(xxs) {
      justify-content: space-between;
    }

    p {
      color: $secondary-color;
    }

    &:not(.fastProposal) {
      use {
        fill: $primary-color;
      }
    }

    &.fastProposal {
      use {
        fill: #e0e225;
      }
    }

    &.resaleFast {
      use {
        fill: $primary-color;
      }
    }

    @include screen(xs) {
      > div:nth-last-child(2),
      :nth-last-child(3) {
        display: none;
      }
    }
  }

  .details {
    transition: height 2s linear;
    overflow: hidden;
    text-align: center;
    margin-top: 24px;

    .allFeatures {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      border-radius: 5px;
      background-color: $background-color;
      margin-bottom: 28px;
      padding: 26px;
      gap: 2px;

      .featureItem {
        display: flex;
        align-items: center;

        &:last-child {
          grid-column: span 3;
          width: 100%;
        }
      }

      [class*='MoreFeatures_text'] {
        p {
          color: $secondary-color;
        }
      }
    }

    summary::-webkit-details-marker {
      display: none;
    }
    > summary {
      text-align: center;
    }
  }

  @include screen(xs) {
    .details {
      .allFeatures {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
