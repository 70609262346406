@import '../../assets/scss/variables.scss';

.input-range__track {
  border-radius: 3px;
  background: #d8d8d8;
  height: 4px;
}

.input-range__track--active {
  background: #7896ff;
}

.input-range__slider {
  width: 6px;
  height: 6px;
  max-width: 6px;
  max-height: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  border: solid 4px #7896ff;
  background: $white-color;
  margin-top: -9px;
}
