@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.text {
  position: relative;
  top: 40px;

  &:nth-child(even) {
    background-color: blue;
  }

  @include screen(xs) {
    font-size: $font-size-12;
    word-break: normal;
  }
}

.timeline {
  width: 100%;
  position: relative;
  perspective: 5000px;
  display: flex;
  justify-content: space-around;

  @include screen(xs) {
    margin-top: 20px;
  }

  &:before {
    position: absolute;
    content: '';
    width: 84%;
    height: 6px;
    background-color: $gray2;
    border-radius: 15px;
    top: 10px;
  }

  .timelineItem {
    text-align: center;
    position: relative;

    &.selected {
      &:after {
        background-color: $white-color;
        border: 5px solid $tertiary-color;
        transform: scale(1.5, 1.5);
      }
    }

    &:after {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0px;

      left: 50%;
      margin-left: -15px;
      background-color: $white-color;
      border: 5px solid $gray2;
      content: '';
      border-radius: 50%;
    }

    .timeline-content {
      position: absolute;
      top: -300px;
      display: none;
      width: 350px;
      left: -60px;
    }

    @include screen(max-sm) {
      &:nth-child(odd) {
        .text {
          top: -35px;
        }
      }
    }
  }
}

.flexContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flexItemsDefault {
  width: 10%;
  flex: 1;
  text-align: center;
}
