@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.discount {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .text {
    font-size: $font-size-14;
    color: $secondary-color;

    @include screen(max-md) {
      font-size: $font-size-16;
    }
  }

  .percent {
    color: $tertiary-color;
    font-weight: 700;
    margin: 0;
  }
  .percent.medium {
    font-size: $font-size-18;
  }

  .percent.large {
    font-size: $font-size-24;
  }

  span {
    display: inline-flex;
    align-items: center;
  }
}
