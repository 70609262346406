@import '../../../../assets/scss/variables.scss';
@import '../../../../assets/scss/mixins.scss';

.container {
  .darkColor {
    color: $secondary-color;
  }

  .strong {
    strong {
      font-weight: bold;
    }
  }
}
