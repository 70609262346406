@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

$alert-card-padding: 22px;
$card-height: 412px;

.alertCard {
  @include flexBox(row, space-between, center);
  box-sizing: border-box;
  width: 95%;
  height: 208px;
  border-radius: 12px;
  background-color: $blue1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 56px 104px 56px 104px;
  margin-top: 12px;
  margin-bottom: 32px;

  .left {
    @include flexBox(row, center, center);
    gap: 24px;
    width: 40%;

    .text {
      font-size: 16px;
      margin-top: 8px;
    }

    @include screen(xs) {
      @include flexBox(column, center, center);

      .text {
        text-align: center;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    @include screen(max-sm) {
      flex-direction: column;
      flex: 0.8;
      gap: 8px;

      .text {
        text-align: center;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  > hr {
    border: 1px solid #919aad;
    height: 89px;
  }

  .rigth {
    flex: 0.7;
    @include flexBox(column, center, flex-start);

    .textBold {
      font-size: 16px;
    }

    .emailInfo {
      display: flex;
      gap: 12px;
      margin-top: 10px;
      width: 100%;

      .emailInput {
        width: 98%;
        height: 40px;

        label {
          margin: 0;
        }

        input {
          border-radius: 4px;
        }

        span {
          font-size: 12px;
        }
      }

      @include screen(xs) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      @include screen(max-sm) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    @include screen(max-sm) {
      .textBold {
        font-size: 14px;
      }
    }
  }

  .figure {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    border-radius: 50%;

    .img {
      width: 96px;
      height: 96px;
    }
  }

  .buttonSubmit {
    width: 160px;
    height: 40px;
    border-radius: 8px;
    background-color: #02bdb2;
  }

  @include screen(max-sm) {
    display: none;
  }

  @include screen(max-md) {
    gap: 12px;
    padding: 24px;
    height: auto;
  }

  @include screen(xxl) {
    width: 89.4%;
  }
}

.containerAlertCard {
  div[class*='alertCard'] {
    [class*='container'] {
      [class*='body'] {
        [class*='info'] {
          [class*='emailInfo'] {
            > button {
              background-color: #02bdb2;
            }
          }
        }
      }
    }
  }

  @include screen(min-md) {
    display: none;
  }
}
