/* 
      Device = Most of the Smartphones Mobiles (Portrait)
      Screen = B/w 320px to 479px
    */
@media (max-width: 480px) {
  [class='wrappedModal'] {
    padding: 1.2em; }
  @supports (-webkit-touch-callout: none) {
    .modalClass > span {
      transform: translateY(15px); } } }

.modalClass {
  box-sizing: border-box;
  width: 45vw;
  max-width: 545px;
  height: 90vh;
  max-height: 850px;
  border-radius: 16px;
  padding: 0;
  overflow-y: hidden;
  /* 
      Device = Most of the Smartphones Mobiles (Portrait)
      Screen = B/w 320px to 479px
    */ }
  .modalClass.success {
    box-sizing: border-box;
    width: 100%;
    overflow-y: hidden;
    max-width: 548px;
    height: 100%;
    max-height: 370px; }
  @media (max-width: 1280px) {
    .modalClass {
      width: 100%; } }
  @media (max-width: 480px) {
    .modalClass {
      width: 100vw;
      height: 100vh; } }

.simulatorModal {
  display: flex;
  flex-direction: column;
  margin: 9px 14px;
  zoom: 0.9;
  /* 
      Device = Most of the Smartphones Mobiles (Portrait)
      Screen = B/w 320px to 479px
    */ }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .simulatorModal {
      zoom: 1; } }
  @media (max-width: 480px) {
    .simulatorModal {
      padding-bottom: 20%; }
      @supports (-webkit-touch-callout: none) {
        .simulatorModal {
          margin-top: 29px; } } }
  .simulatorModal .top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%; }
    .simulatorModal .top .title {
      color: #666666;
      font-weight: 400;
      line-height: 26px; }
  .simulatorModal > .header {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%; }
    .simulatorModal > .header > .info {
      display: flex;
      flex-direction: column; }
      .simulatorModal > .header > .info .contactText {
        margin-top: -2px; }
      .simulatorModal > .header > .info .description {
        margin-top: 14px; }
  .simulatorModal > .main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 29px;
    position: relative;
    /* 
      Device = Low Resolution Tablets, Mobiles (Landscape)
      Screen = B/w 481px to 767px
    */ }
    .simulatorModal > .main .modalLabel {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: #666666; }
    .simulatorModal > .main > .inputName {
      width: 100%;
      margin-top: 16px; }
    .simulatorModal > .main > .inputPhone,
    .simulatorModal > .main > .inputEmail {
      margin-top: 3px; }
    .simulatorModal > .main > .selectPeriod,
    .simulatorModal > .main > .selectDay {
      margin-top: 6px; }
    .simulatorModal > .main > .inputPhone,
    .simulatorModal > .main > .selectPeriod {
      width: 50%;
      padding-left: 3%;
      box-sizing: border-box; }
    .simulatorModal > .main > .inputEmail,
    .simulatorModal > .main > .selectDay {
      width: 50%;
      padding-right: 2%;
      box-sizing: border-box; }
    .simulatorModal > .main > .inputSubject {
      width: 100%;
      margin-top: 16px; }
      .simulatorModal > .main > .inputSubject label p,
      .simulatorModal > .main > .inputSubject label select {
        color: #666666; }
    .simulatorModal > .main input {
      height: 40px; }
    .simulatorModal > .main > .inputName,
    .simulatorModal > .main > .inputPhone {
      position: relative; }
      .simulatorModal > .main > .inputName > span,
      .simulatorModal > .main > .inputPhone > span {
        position: absolute;
        bottom: -16px;
        right: 0;
        font-size: 12px; }
    .simulatorModal > .main > .inputEmail {
      position: relative; }
      .simulatorModal > .main > .inputEmail > span {
        position: absolute;
        bottom: -16px;
        right: 11px;
        font-size: 12px; }
    .simulatorModal > .main .valuesContainer {
      margin-top: 1rem;
      background-color: #f4f4f4;
      border-radius: 8px;
      width: 100%;
      padding: 16px 24px; }
      .simulatorModal > .main .valuesContainer p {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 4px; }
        .simulatorModal > .main .valuesContainer p:first-child {
          margin-top: 0; }
    @media (max-width: 767px) {
      .simulatorModal > .main .selectPeriod {
        width: 100%;
        padding-left: 0; }
      .simulatorModal > .main .selectDay {
        width: 100%; } }
    .simulatorModal > .main .warning {
      position: absolute;
      bottom: -5%;
      left: 0;
      color: #fb4e5f;
      font-size: 14px;
      /* 
      Device = Most of the Smartphones Mobiles (Portrait)
      Screen = B/w 320px to 479px
    */ }
      @media (max-width: 480px) {
        .simulatorModal > .main .warning {
          position: static;
          max-width: 40ch; } }
  .simulatorModal > .footer {
    justify-content: center;
    display: flex;
    margin-top: 2%; }
    .simulatorModal > .footer > .button {
      width: 100%;
      margin: 5px;
      border-radius: 8px;
      height: 7vh; }
