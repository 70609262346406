@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';

.estateHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  z-index: 1;
  opacity: 1;
  position: sticky;
  transition: all 1s;
  transform: translateY(0px);
  box-sizing: border-box;

  .filterSection {
    gap: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;
    box-sizing: border-box;
    width: 100%;
    background-color: $white-color;
    z-index: 1;

    .localityInput {
      width: 240px;

      input {
        border: 1px solid #bdbdbd !important;
      }
      input::placeholder {
        color: #484848;
      }

      svg {
        color: #262626;
      }
    }

    @include screen(max-sm) {
      display: none;
    }

    label[class*='container'] {
      margin-top: 12px;
    }
  }

  .resultsSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: $background-color;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 14px;
    overflow: hidden;

    @include screen(max-sm) {
      flex-direction: column;
      padding-top: 8px;
      padding-bottom: 14px;

      .amountResults {
        margin: 4px 0;
      }
    }

    .filtersButtons {
      display: grid;
      grid-template-rows: 37px;
      grid-template-columns: repeat(2, 1fr);
      place-content: center;
      place-items: center;
      gap: 5px;

      button,
      select {
        box-sizing: border-box;
        height: 37px;
        min-height: 37px;
        max-height: 37px;
      }

      @include screen(min-md) {
        .buttonFilterMobile {
          display: none;
        }
        grid-template-columns: 1fr;
      }
    }

    .mobile {
      width: 100%;
      height: auto;
      @include screen(min-md) {
        display: none;
      }
    }

    .mobileNone {
      transition: all 1s;
      transform: translateY(-12px);
    }

    .mobileShow {
      transition: all 1s;
      transform: translateY(0px);
    }

    .textHeader {
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 15px;
        margin-bottom: 10px;
        font-weight: normal;
        padding-left: 28px;
        @include screen(max-sm) {
          padding-left: 0;
          text-align: center;
        }
      }

      .amountResultsNone {
        transition: all 1s;
        transform: translateY(36px);
      }
      .amountResultsShow {
        transition: all 1s;
        transform: translateY(0px);
      }
    }
  }

  .resultsSectionNone {
    z-index: 0;
    transition: all 1s;
    transform: translateY(-104px);

    @include screen(max-sm) {
      transform: translateY(-32px);
      visibility: hidden;
    }
  }
  .resultsSectionShow {
    transition: all 1s;
    transform: translateY(0px);
  }

  @include screen(max-sm) {
    flex-direction: column;
    padding-top: 0px;
    margin-bottom: 0;
  }
}

.displayNone {
  transition: all 1s;
  transform: translateY(-100px);
}

.displayShow {
  transition: all 1s;
  transform: translateY(-6px);
}

.btnFilter {
  background-color: $tertiary-color;
  border-color: $tertiary-color;
  color: $white-color;
  font-size: $font-size-14;
  height: 37px !important;
  width: 150px;
}

.amountResults {
  padding-left: 25px;
  @include screen(max-sm) {
    padding-left: 0;
  }
}

.selectSorting {
  padding-right: 25px;

  @include screen(max-sm) {
    padding-right: 0;
  }
}
