@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.lightBlueColor {
  background-color: $lavender1;
  border-radius: 8px;
}

.lightResaleColor {
  background-color: $tertiary-color;
  border-radius: 8px;
}

.largeButton {
  gap: 5px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 8px;
  height: 40px;
  background-color: $tertiary-color;

  @include screen(max-md) {
    width: 98%;
  }
}
