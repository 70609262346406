@import '../../assets/scss/variables.scss';

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid $primary-color;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  display: inline-block;

  &.small {
    width: 15px;
    height: 15px;
  }

  &.medium {
    width: 30px;
    height: 30px;
  }
  
  &.large {
    width: 60px;
    height: 60px;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
