@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.carousel {
  height: 100%;
  margin: 0;
  width: 100%;
  position: relative;
  max-height: 555px;

  @include screen(max-md) {
    .left,
    .right {
      display: none;
    }
  }
}

.carouselMobile {
  display: inline;
  width: 100vw;

  img[src='data/image'] {
    visibility: hidden;
  }
  .wrapper {
    display: flex;
  }
  .slide {
    width: 100%;
  }
  .swiper{
    padding: 0 10px;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  list-style-type: none;
}

.swiper {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  text-align: center;
  width: 100vw;
  height: 100%;
  max-height: 555px;
  @media (max-width: 480px) {
    max-height: 280px;
  }
}

.containerSlide {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.pagination {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 86px; /* only show 5 dots */
  height: 20px;
  overflow: hidden;
  margin-top: -20px;
}

.pagination ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.pagination a {
  display: inline-block;
  padding: 10px 18px;
  color: $gray7;
  transition: all 1s ease;
}

.p6 a {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  padding: 0;
  margin: 4px;
  text-align: center;
  position: relative;
  background-color: $gray5-translucid;
  transition: height width 1s ease;
}

.p6 .is-active {
  width: 10px;
  height: 10px;
  background-color: $primary-color;
  transition: height width 1s ease;
}

@include screen(max-sm) {
  .carousel {
    display: none;
  }

  .pagination {
    width: 96px;
  }

  .p6 a {
    width: 10px;
    height: 10px;
  }

  .p6 .is-active {
    width: 13px;
    height: 13px;
  }
}

@include screen(max-sm) {
  .p6 a {
    width: 10px;
    height: 10px;
  }
}

@include screen(xs) {
  .pagination {
    width: 86px;
  }

  .p6 a {
    width: 7px;
    height: 7px;
  }

  .p6 .is-active {
    width: 10px;
    height: 10px;
  }

  .slideIphone {
    height: 100% !important;
  }
}

@include screen(min-md) {
  .carouselMobile {
    display: none;
  }
}
