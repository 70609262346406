@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  .label {
    text-align: left;
    position: relative;
    font-size: $font-size-16;
    color: $text-color;
    margin: 5px 0 5px 0;
  }
  .span {
    text-align: end;
    color: $tertiary-color;
    font-size: $font-size-14;
  }
  .input {
    border-radius: 4px;
    padding: 0px 11px;
    font-size: $font-size-14;
    color: $blue1;

    border: 1px solid $line-color;

    box-sizing: border-box;
    outline: 0;
    height: 32px;
    width: 100%;

    &:focus {
      outline: 0;
    }

    &::after {
      content: '';
      width: 100%;
      height: 33px;
    }

    @include screen(max-sm) {
      font-size: 16px;
    }

    &.disabled {
      border-color: #dadada !important;
      background-color: #f5f5f5 !important;
    }
  }
}
.wrapper.error {
  .input {
    border-color: $tertiary-color !important;
  }

  .label {
    color: $tertiary-color;
  }

  .span {
    text-align: end;
    color: $tertiary-color;
    font-size: $font-size-14;
  }
}
