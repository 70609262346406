@import '../../assets/scss/variables.scss';

.contentWrapper {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: $lavender1;

  > p {
    margin: 0px;
    white-space: normal;
    word-break: break-word;
    font-size: $font-size-16;
    line-height: 1.38;
    letter-spacing: 0.21px;
    color: $secondary-color;
    font-weight: 400;
    text-align: left;
    font-size: 14px;
    max-width: 65%;
  }
}

.textTitle {
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  color: $secondary-color;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;

  > .contentWrapper {
    width: auto;
  }
}

strong {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: $secondary-color;
}
