[class*='multiple-carousel'] {
  z-index: 2; }

.modalCamp {
  width: 410px;
  padding: unset;
  border: unset;
  background-color: transparent;
  overflow: hidden;
  /* 
      Device = Most of the Smartphones Mobiles (Portrait)
      Screen = B/w 320px to 479px
    */
  /* 
      Device = Most of the Smartphones Mobiles (Portrait)
      Screen = B/w 320px to 479px
    */ }
  .modalCamp .countDown {
    margin-bottom: 18%; }
    .modalCamp .countDown span[class*='title'],
    .modalCamp .countDown span[class*='subTitle'] {
      color: #ffffff !important; }
  @media (max-width: 480px) {
    .modalCamp {
      width: 97vw; } }
  .modalCamp [class*='countDownContainer'] {
    /* 
      Device = Low Resolution Tablets, Mobiles (Landscape)
      Screen = B/w 481px to 767px
    */ }
    @media (max-width: 767px) {
      .modalCamp [class*='countDownContainer'] {
        visibility: unset; } }
  .modalCamp [class*='image'] {
    border-radius: 16px;
    height: 530px;
    object-fit: fill;
    image-rendering: -webkit-optimize-contrast;
    cursor: pointer; }
  .modalCamp [class*='clear'] {
    top: 24px;
    left: unset !important;
    right: 18px; }
    .modalCamp [class*='clear'] use {
      fill: #ffffff !important; }
  .modalCamp div {
    position: relative; }
    .modalCamp div .countDown {
      position: absolute;
      left: 24%;
      bottom: 16%;
      margin-top: 2%;
      /* 
      Device = Low Resolution Tablets, Mobiles (Landscape)
      Screen = B/w 481px to 767px
    */ }
      @media (max-width: 767px) {
        .modalCamp div .countDown {
          left: 20%; } }
      @media (min-width: 320px) and (max-width: 324px) {
        .modalCamp div .countDown {
          left: 16%; } }
  .modalCamp button[class*='react-multiple-carousel__arrow'] {
    box-shadow: unset;
    color: #fff9f9 !important;
    margin: 0 auto;
    transition: all 0.3s ease 0s;
    top: 50%;
    background-color: #f9f9f9 !important;
    min-width: unset;
    min-height: unset;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    z-index: 1; }
    .modalCamp button[class*='react-multiple-carousel__arrow']::before {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000000;
      font-size: 12px; }
    .modalCamp button[class*='react-multiple-carousel__arrow']:hover {
      color: #111111 !important; }
  .modalCamp div[class*='magic-dots'] {
    position: absolute !important;
    bottom: 2% !important;
    margin: 0 auto; }
    .modalCamp div[class*='magic-dots'] > ul > li button::before {
      opacity: unset;
      color: #ffffff; }
    .modalCamp div[class*='magic-dots'] > ul > li[class*='active'] button::before {
      color: #7896ff; }
  .modalCamp div[class*='slick-slider'] {
    display: flex;
    justify-content: center; }
  .modalCamp div[class*='slick-list'] {
    width: 100%;
    height: 100%; }
  @media (max-width: 480px) {
    .modalCamp {
      margin-top: 24px; } }
