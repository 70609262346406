@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.notFoundCard {
  @include flexBox(column, space-between);
  text-align: center;
  padding: 10px 20px;
  margin: 0 14px;
  width: 55%;

  .header {
    text-align: center;
    margin-bottom: $notification-box-rosie-size/1.5;
  }

  .title {
    margin-bottom: 8px;
  }

  @include screen(max-md) {
    width: auto;
    flex-direction: column;
    justify-content: flex-start;
    background-color: $asterisk-background-color;
  }

  @include screen(xs) {
    width: 100%;
  }
}
