@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';

.timer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;

  .timerLabel {
    max-width: 45%;
    line-height: 1.1;
    margin-bottom: 10px;
    align-self: center;

    p {
      padding-right: 20px;
      font-size: $font-size-14;
      color: $secondary-color;
    }
  }

  .countdown {
    max-width: 50%;

    div[class*='box'] {
      span[class*='numberBox'] {
        @include screen(max-md) {
          background-color: #e9ebee;
        }
      }
    }
  }

  .textBold {
    font-weight: bold;
  }
}
